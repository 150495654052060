.slider {
     --slider-slide-number: 0;
    
    width: 100%;
    height: 60%;
    box-sizing: border-box;
}
.slider::after {
    content: '';
    display: block;
    width: 100%;
    height: 15px;
    position: absolute;
    top: 70%;
    left: 0;
    background-color: var(--main-hover-bgColor);
}
.outer { 
    --slider-drag-offset: 0;
    
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    z-index: 1;
    transition: transform .1s;
}
.inner {
    width: 65%;
    height: 100%;
    padding-bottom: 30px;
    margin: 0 auto;
}
.content {
    display: flex;
    height: 100%;
    transform: translate(calc(var(--slider-slide-number) * -110% + var(--slider-drag-offset) * 1px));
    transition: transform var(--main-hover-transition);
}
.slide {
    width: 100%;
    flex-shrink: 0;
    transition: transform var(--main-hover-transition);
}
.slide.current,
.slide.current ~ .slide {
    transform: translate(0, 0);
}
.desktop-image {
    height: 100%;
}
.prev,
.next{
    width: 25%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
}
.prev {
    left: 0;
}
.next {
    right: 0;
}
@media (min-width: 720px) {
    .slide {
        transform: translate(-25%, 0);
        box-sizing: border-box;
        padding-right: 10%;
    }
    .slide + .slide { 
        margin-left: 10%; 
    }
}
@media (max-width: 720px) {
    .slider {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 24vh;
        padding-bottom: 5vw;
    }
    .slider::after {
        bottom: auto;
        top: 50%;
    }
    .outer {
        order: 1;
        padding-bottom: 0;
        overflow: hidden;
    }
    .inner {
        width: 90%;
    }
    .slide + .slide { 
        margin-left: 10%; 
    }
    .next,
    .prev {
        display: none;
    }
}
