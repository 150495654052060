.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background-color: black;
    animation-name: in;
    animation-duration: 0.5s;
}
.content {
    width: 100%;
    height: 100%;
}
.close {
    background-color: var(--main-hover-bgColor);
    width: 40px;
    height: 40px;
    position: fixed;
    top: 40px;
    right: 40px;
    border: 10px solid var(--main-hover-bgColor);
    z-index: 1; 
}
.inner {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 1s;
}
.inner:hover {
    transform: rotate(720deg);
}
.inner::before,
.inner::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: black;
}
.inner::before {
    transform: translate(0, 20px) scale(1.41) rotate(45deg);
    transform-origin: center;
}
.inner::after {
    transform: translate(0, 20px) scale(1.41) rotate(-45deg);
    transform-origin: center;
}

@keyframes in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
