.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    background-color: var(--main-bg-color);
}
.main{
    width: 100%;
    position: relative;
}
.logo,
.menu {
    position: absolute;
    z-index: 1;
}
.hamburger {
    position: absolute;
    z-index: 2;
}
.siteMap {
    z-index: 1;
}

.content {
    position: relative;
    z-index: 0;
    width: 100%;
    min-height: 100%;
}


@media (min-width: 720px) {
    .logo {
        top: 64px;
        left: 76px;
        width: 8%;
        min-width: 120px;
    }
    .menu {
        top: 93px;
        right: 292px;
        z-index: 1;
    }
    .hamburger {
        top: 50%;
        left: 53px;
    }
}
@media (max-width: 720px) {
    .logo {
        top: 22px;
        left: 27px;
        width: 49px;
    }
    .menu {
        display: none;
    }
    .hamburger {
        top: 0;
        right: 0;
    }
}
