.wrapper {
    overflow: hidden;
    display: flex;
    width: 100%;
    position: absolute;
    background-color: var(--main-hover-bgColor: #c6ff00);
    transition: background-color 0.3s;
    z-index: 9;
}
.isOpen {
    top: 0;
    bottom: 0;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
}


@media (min-width: 720px) {
    .wrapper,
    .wrapper.isClosed {
        top: 50vh;
        bottom: 50vh;
        left: 0;
    }
    .wrapper.isClosed {
        animation-name: desktop-out;
        animation-duration: 0.45s;
    }
    .wrapper.isOpen {
        top: 0;
        bottom: 0;
        animation-name: desktop-in;
        animation-duration: 0.8s;
    }
    .wrapper .content {
        opacity: 0;
        transition: 0.2s;
    }
    .wrapper.isOpen .content {
        opacity: 1;
        transition: 0.4s 0.6s;
    }
    .isInverted {
        background-color: white;
    }
}
@media (max-width: 720px) {
    .wrapper,
    .wrapper.isClosed {
        right: 0;
        top: 0;
        width: 0;
        height: 0;
    }
    .wrapper.isClosed {
        animation-name: mobile-out;
        animation-duration: 0.8s;
    }
    .wrapper.isOpen {
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation-name: mobile-in;
        animation-duration: 0.8s;
    }
    .wrapper .content {
        opacity: 0;
        transition: 0.2s;
    }
    .wrapper.isOpen .content {
        opacity: 1;
        transition: 0.4s 0.6s;
    }
}

@keyframes desktop-in {
    0% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: 0;
        bottom: 0;
    }
}
@keyframes desktop-out {
    0% {
        top: 0;
        bottom: 0;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
}
@keyframes mobile-in {
    0% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
    30% {
        height: 65px;
        opacity: 1;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 100%;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}
@keyframes mobile-out {
    0% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    30% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
}
