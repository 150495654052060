.wrapper {
    height: 100%;
}
    .wrapper.animated {
        position: absolute;
        z-index: 1;
    }
        .wrapper.animated .image {
            padding-bottom: 0;
            height: 100%;
        }
        .wrapper.animated .layout {
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: black;
            position: absolute;
            top: 0;
            left: 0;
        }
.wrapper.hidden {
    visibility: hidden;
}

.inner {
    width: 100%;
    height: 100%;
}
.button {
    width: 197px;
    height: 65px;
    position: absolute;
    right: calc(-10% + 195px);
    bottom: -87px;
    box-sizing: border-box;
    color: var(--main-hover-color);
    /*background-color: var(--main-hover-bgColor);*/
    text-align: center;
    font-size: 40px;
    line-height: 30px;
    cursor: pointer;
}
.title {
    color: var(--main-text-color);
    font-size: 81px;
    white-space: pre-wrap;
    user-select: none;
}
.image,
.mobileImage {
    background-position: center;
    background-size: cover;
    width: 100%;
    display: none;
}

@media (min-width: 720px) {
    .inner {
        position: relative;
    }
    .image {
        display: block;
        height: 100%;
    }
    .title {
        position: absolute;
        top: 50%;
        right: calc(-10% - 50px);
        transform: translateY(-50%);
    }
}
@media (max-width: 720px) {
    .wrapper {
        height: 100%;
        width: 100%;
        padding: 17px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
    }
    .inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .button {
        order: 1;
        width: 100%;
        height: 64px;
        right: auto;
        position: relative;
        bottom: auto;
        transform: none;
        font-size: 41px;
        letter-spacing: 0.1ex;
    }
    .title {
        order: 0;
        position: relative;
        font-size: 29px;
        line-height: 1;
        letter-spacing: 0.1ex;
        margin: 0 3px 13px;
    }
    .mobileImage {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
    }
}
