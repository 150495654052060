.imagesForMenuItems {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.imageWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity .3s;
    animation-name: appearing;
    animation-duration: 0.4s;
}
.isUnactive {
    opacity: 0;
    animation-name: disappearing;
    animation-duration: 0.3s;
}
.image {
    min-width: 100%;
    height: 100%;
}

@media (max-width: 720px) {
    .imagesForMenuItems {
        display: none;
    }
}

@keyframes appearing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes disappearing {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
