.panes {
    width: 100%;
    height: 100%;
}
.pane {
    overflow: auto;
}
.first {
    background-color: var(--main-bg-color);
}
.second {
    background-color: white;
}

@media (min-width: 720px) {
    .panes {
        --offset: 0;

        display: flex;
        flex-direction: row;
    }
    .scrollable {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .pane {
        width: 50%;
        transition: width 0.1s;
    }
    .panes.catched .pane {
        transition: none;
    }
    .first {
        width: calc(50% + var(--offset) * 1px);
    }
    .first.fixed {
        display: none;
    }
    .second {
        position: relative;
        width: calc(50% - var(--offset) * 1px);
    }

    .handle {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        user-select: none;
        background-color: white;
    }
    .handle::before {
        display: block;
        content: '';
        width: 5px;
        height: 80px;
        border-radius: 3px;
        background-color: #ececec;
    }

    .panes.collapsed .pane.first {
        width: calc(100% - 51px + var(--offset) * 1px);
    }
    .panes.collapsed .pane.second {
        width: calc(51px - var(--offset) * 1px);
    }
}
@media (max-width: 720px) {
    .panes {
        position: relative;
    }
    .first {
        position: fixed;
        top: 0;
        height: 70vh;
        width: 100%;
        opacity: 0;
    }
    .first:hover {
        opacity: 1;
    }
    .first.fixed {
        opacity: 1;
    }
    .scrollable {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        padding-top: 70vh;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .second {
        padding-top: calc(15px * 2 + 7px);
        position: relative;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 30px;
    }
    .second::before {
        display: block;
        content: '';
        width: 94px;
        height: 7px;
        background-color: #EBEBEB;
        border-radius: 4px;
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin: auto;
    }
}
