.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: var(--main-bg-color);
}
.map {
    height: 100%;
}
.contacts {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
}
.info {}
.top {}
.middle {
    font-weight: lighter;
}
.info p {
    margin-top: 0;
    margin-bottom: 0;
}

.bottom {
    font-weight: lighter;
}
.contact {
    display: block;
    margin-bottom: 25px;
    text-decoration: none;
    color: #000;
}
.contact:last-child {
    margin-bottom: 0;
}

@media (min-width: 720px) {
    .wrapper {
        padding-left: 20%;
    }
    .contacts {
        padding-right: calc(20px + 10%);
        padding-left: calc(20px + 10%);
    }

    .info {
        font-size: 36px;
    }
    .top {
        margin-bottom: 80px;
    }
    .middle {
        margin-bottom: 60px;
    }
    .bottom {
        font-size: 24px;
    }

    .info p + p {
        margin-top: 0.7em;
    }
}
@media (max-width: 720px) {
    .info {
        font-size: 22px;
        width: 100%;
    }

    .top {
        margin-bottom: 1em;
    }
    .middle {
        margin-bottom: 1em;
    }

    .info p + p {
        margin-top: 0.2em;
    }
}
