.first {
    height: 100%;
    background-size: cover;
}
.second {
    min-height: 100%;
    position: relative;
    overflow: auto;
}
.btnClose::after,
.btnClose::before {
    content: '';
    display: block;
    width: 141%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: -20%;
    transform: translateY(-50%);
    background-color: #000;
    pointer-events: none;
}
.btnClose::after {
    transform: rotate(45deg);
}
.btnClose::before  {
    transform: rotate(-45deg);
}
.content {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.content h2,
.content h3,
.content p {
    margin-top: 0;
    margin-bottom: 0;
}
.content h2 {
    font-weight: bold;
    font-size: 1.5em;
}
.content h3 {
    font-weight: bold;
    font-size: 1.25em;
    margin-top: 0.2em;
}
.content p {
    margin-top: 2.8em;
}
.content p + p {
    margin-top: 1.6em;
}

@media (min-width: 720px) {
    .btnClose {
        position: absolute;
        width: 51px;
        height: 51px;
        top: 75px;
        right: 75px;
        transition: transform 1s;    
    }
    .btnClose:hover {
        transform: rotate(720deg);
    }
    .content {
        margin: 30%  0 20% 120px;
        width: 60%;
    }
}
@media (max-width: 720px) {
    .first {
        background-position: left center;
    }
    .second {
        /*
            on iOS devices overflow auto cuts fixed children elements (.btnClose in this case)
            so both following rules are for fixing that bug
         */
        margin-right: -10%;
        padding-right: 10%;
    }
    .btnClose {
        position: fixed;
        width: 46px;
        height: 46px;
        right: 12px;
        bottom: calc(30vh + 12px);
        background-color: white;
        border: 10px solid white;
        box-sizing: border-box;
    }
}
