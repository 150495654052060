html,
body {
    height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: MuseoSansCyrl, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
    height: 100%;
    --main-bg-color: #141414;
    --main-link-color: #fff;
    --main-text-color: #fff;
    --main-hover-color: #000;
    --main-hover-bgColor: #c6ff00;
    --main-hover-transition: .3s;
    --sidebar-width: 190px;
}

/* prevent ios bouncing scroll */
@media (max-width: 720px) {
    html {
        height: 100%;
        overflow: hidden;
    }
    body {
        height: 100%;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}


.fade-enter {}

.fade-enter-active {
    z-index: 0;
}

.fade-exit {}

.fade-exit-active {
    z-index: 1;
}

.slogan-enter-active {
    opacity: 1 !important;
    z-index: 9999 !important;
}
.slogan-enter-done {
    opacity: 1 !important;
    z-index: 9999 !important;
}
.slogan-exit-active {
    opacity: 0 !important;
    z-index: 9999 !important;
}
.slogan-exit-done {
    opacity: 0 !important;
    z-index: -1 !important;
}