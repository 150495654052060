.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.text {
    user-select: none;
}