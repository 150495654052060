.wrapper {
    width: 100%;
    height: 100%;
}
.inner {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.title,
.text {
    color: var(--main-text-color);
}
.title {}
.text {
    font-weight: lighter;
}
.labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.label {
    display: flex;
    width: 35%;
    height: 125px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    background-blend-mode: lighten;
}
.desktop-image {
    width: 100%;
}
.top {}
.bottom {}

@media (min-width: 720px) {
    .inner {
        padding-top: 240px;
        padding-bottom: 40px;
    }
    .first {
        padding-right: calc(20px + 10%);
        padding-left: calc(var(--sidebar-width) + 8%);
    }
    .second {
        padding-right: calc(var(--sidebar-width) + 8%);
        padding-left: calc(20px + 10%);
    }

    .title,
    .text {
        font-size: 35px;
    }
    .title {
        margin-bottom: 36px;
    }
    .text {
        margin-bottom: 72px;
    }

    .top {
        font-size: 24px;
        margin-bottom: 78px;
    }
    .bottom {
        font-size: 16px;
    }
    .top .item + .item {
        margin-top: 49px;
    }
    .bottom .item + .item {
        margin-top: 59px;
    }
    .dots {
        display: none;
    }
}
@media (max-width: 720px) {
    .inner {
    }
    .first {
        padding: 107px 10% 0;
        overflow: hidden;
    }

    .title,
    .text {
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.1ex;
    }
    .text {
        margin-top: 0.7em;
        margin-bottom: 1.5em;
    }

    .top {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 2em;
        text-align: center;
    }
    .bottom {
        font-size: 16px;
        text-align: center;
    }

    .top .item + .item {
        margin-top: 1.5em;
    }
    .bottom .item + .item {
        margin-top: 1.7em;
    }
    .labels {
        --slider-slide-number: 0;
        --slider-drag-offset: 0;
        
        flex-wrap: nowrap;
        padding-bottom: 20px;
        transform: translate(calc(var(--slider-slide-number) * -100% + var(--slider-drag-offset) * 1px));
        transition: transform .1s;
    }
    .label {
        flex-shrink: 0;
        width: 70%;
        height: 90px;
        margin: 0 15%;
    }
    .dots {
        width: 100%;
        height: 11px;
        display: flex;
        justify-content: center;
    }
    .dot {
        width: 11px;
        height: 11px;
        margin-right: 20px;
        background-color: #fff;
        transition: background-color var(--main-hover-transition);
    }
    .dotActive {
        background-color: var(--main-hover-bgColor);
    }
    .dot:last-child {
        margin-right: 0;
    }
}
