.logo {
    display: block;
    position: relative;
    z-index: 1;
}
.desktopImage,
.mobileImage {
    display: none;
    width: 100%;
    height: 100%;
}
.logo {
    width: 100%;
}

@media (min-width: 720px) {
    .desktopImage {
        display: block;
    }
}
@media (max-width: 720px) {
    .mobileImage {
        display: block;
    }
}