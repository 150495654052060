.detail {
    --vertical-offset: 0;

    width: 100%;
    height: 100%;
}
.background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
}
.backgroundDark::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .5);
}
.sideBar {
    display: flex;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}
.first {
    width: 100%;
    position: relative;
    padding-left: 20%;
    padding-right: var(--sidebar-width);
    overflow-y: auto;
}
.wrapper {
    width: 100%;
    /*height: 100%;*/
    position: relative;
}
.outer {
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    align-items: flex-start;
    box-sizing: border-box;
}
.info {
    width: 100%;
    color: var(--main-text-color);
}
.title,
.text {
    white-space: pre-wrap;
}
.title {
    font-size: 81px;
    margin-bottom: 48px;
}
.text {
    width: 589px;
    font-size: 18px;
}
.close{
    width: 197px;
    height: 65px;
    position: fixed;
    flex-shrink: 0;
    top: 50%;
    right: 10%;
    transform: translate(-60%, -50%);
    color: var(--main-hover-color);
    align-self: center;
    text-align: center;
    font-size: 40px;
    line-height: 29px;
    cursor: pointer;
}
.second {
    width: var(--sidebar-width);
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
}
.link {
    display: block;
    width: 100%;
    height: 100%;
}
.next {
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.inner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    transition: background-color var(--main-hover-transition);
    color: var(--main-hover-color);
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 21px;
}
.btnText {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
}
.frames {
    width: 92%;
    position: relative;
    z-index: 1;
    border-bottom: 10px solid white;
}
.frame {
    height: 100vh;
    box-sizing: border-box;
    border: 10px solid #fff;
    border-bottom: none;
    background-size: cover;
    background-repeat: no-repeat;
}
@media (min-width: 720px) {
    .detail {
        --vertical-offset: 15vw;
    }
    .first {
        height: 100vh;
    }
    .wrapper {
        padding-top: 5vw;
        padding-bottom: 10vh;
    }
    .info {
        padding-top: var(--vertical-offset);
        padding-right: 250px;
    }
    .inner:hover {
        background-color: var(--main-hover-bgColor);
    }
}
@media (max-width: 720px) {
    .detail {
        --vertical-offset: 30vw;
    }
    .sideBar {
        height: 100%;
    }
    .background {
        background-position: 50% 50%;
    }
    .first {
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-left: 0;
        padding-bottom: 48px;
        box-sizing: border-box;
    }
    .outer {
        padding: 0 5% 0 5%;
    }
    .wrapper {
        padding-top: 10vw;
    }
    .wrapper::after {
        display: block;
        content: '';
        height: 10vh;
    }
    .info {
        width: 89%;
        top: 0;
        margin-top: var(--vertical-offset);
        left: 27px;
        transform: none;
    }
    .title {
        font-size: 28px;
        margin-right: 0;
        margin-bottom: 24px;
    }
    .text {
        width: auto;
        font-size: 12px;
        line-height: 1.5;
    }
    .close {
        width: 50%;
        height: 48px;
        position: fixed;
        top: 100%;
        right: auto;
        left: 0;
        margin-top: -48px;
        transform: none;
        text-transform: lowercase;
    }
    .link {
        text-transform: lowercase;
    }
    .frames {
        width: 100%;
    }
    .frame {
        height: 25vh;
    }
    .second {
        width: 50%;
        height: 48px;
        position: fixed;
        right: 0;
        top: auto;
        bottom: 0;
        z-index: 1;
    }
    .next {
        display: none;
    }
    .inner {
        font-size: 40px;
    }
    .btnText {
        position: relative;
        top: auto;
        transform: none;
    }
}