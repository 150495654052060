.wrapper {
    --link-bg-color: var(--main-hover-bgColor);
    position: relative;
    color: black;
    text-decoration: none;
    z-index: 0;
}
    .bg {
        background-color: var(--link-bg-color);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transform: translate(0);
        transition: opacity var(--main-hover-transition), transform 0.3s;
    }
    .wrapper:hover .bg {
        transition: opacity var(--main-hover-transition), transform 0s;
        opacity: 1;
    }
    .wrapper.isAlwaysShown .bg {
        transition: transform 0s;
        opacity: 1;
    }
@media (max-width: 720px) {
    .wrapper.noMobileBG .bg {
        background-color: transparent;
    }

    .bg {
        transform: translate(0) !important;
    }
}
