.hamburger {
    transition: background-color var(--main-hover-transition);
    cursor: pointer;
}
.top,
.bottom {
    display: block;
    height: 3px;
    background-color: var(--main-link-color);
    transition: background-color var(--main-hover-transition);
}
.top {
    margin-bottom: 9px;
}
.bottom {
    margin-top: 9px;
}
.isOpen .top,
.isOpen .bottom {
    background-color: var(--main-hover-color);
}
.isOpen .top {
    animation: top .6s .2s;
    animation-fill-mode: forwards;
}
.isOpen .bottom {
    animation: bottom .6s .2s;
    animation-fill-mode: forwards;
}
@keyframes top {
    0% {
        
    }
    20% {
        margin-bottom: -3px;
        transform: rotateZ(0);
    }
    100%{
        transform: rotateZ(-45deg);
        margin-bottom: -3px;
    }
}
@keyframes bottom {
    0% {
        
    }
    20% {
        margin-top: -3px;
        transform: rotateZ(0);
    }
    100%{
        transform: rotateZ(45deg);
        margin-top: 0;
    }
}
@media (min-width: 720px) {
    .hamburger {
        padding: 12px 22px;
        transform: translateY(-50%);
    }
    .hamburger:hover {
        background-color: var(--main-hover-bgColor);
    }
    .isOpen {
        padding-top: 42px;
        padding-bottom: 42px;
        transition: transform 1s;
        transform: translateY(-50%) rotate(0deg);
    }
    .isOpen:hover {
        background-color: transparent;
        transform: translateY(-50%) rotate(720deg);
    }
    .hamburger:hover .top,
    .hamburger:hover .bottom {
        background-color: var(--main-hover-color);
    }
    .top,
    .bottom {
        width: 82px;
    }
}
@media (max-width: 720px) {
    .hamburger {
        padding: 25px 20px;
    }
    .top,
    .bottom {
        width: 42px;
    }
}