.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}
.background-base {
    opacity: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.background-in {
    animation-name: background-in;
    animation-duration: 0.7s;
}
.background-out {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation-name: background-out;
    animation-duration: 0.7s;
}

@keyframes background-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes background-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}