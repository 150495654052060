.title {
    position: relative;
}
.title-base {
    transform: translate(0, 0);
    opacity: 1;
}
.title-in {
    animation-name: title-in;
    animation-duration: 0.7s;
}
.title-out {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-300px, 0);
    opacity: 0;
    animation-name: title-out;
    animation-duration: 0.7s;
}

@keyframes title-in {
    0% {
        transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes title-out {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        transform: translate(-300px, 0);
        opacity: 0;
    }
}
.text {
    position: relative;
}
.text-base {
    transform: translate(0, 0);
    opacity: 1;
}
.text-in {
    animation-name: text-in;
    animation-duration: 0.7s;
}
.text-out {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-300px, 0);
    opacity: 0;
    animation-name: text-out;
    animation-duration: 0.7s;
}

@keyframes text-in {
    0% {
        transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes text-out {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        transform: translate(-300px, 0);
        opacity: 0;
    }
}
.close-enter {
    opacity: 0;
}
.close-enter-active {
    opacity: 1;
    transition: opacity .7s .2s;
}
.wrapper {
    position: relative;
}
.wrapper-base {
    transform: translateY(0);
    opacity: 1;
}
.wrapper-in {
    animation-name: wrapper-in;
    animation-duration: 0.7s;
}
.wrapper-out {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(200px);
    opacity: 0;
    animation-name: wrapper-out;
    animation-duration: 0.7s;
}

@keyframes wrapper-in {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes wrapper-out {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        transform: translateY(200px);
        opacity: 0;
    }
}
@media (max-width: 720px) {
    .close-enter {
        opacity: 1;
    }
    .close-enter-active {
        opacity: 1;
        transition: opacity .7s .2s;
    }
}