.index {
    width: 100%;
    height: 100%;
    background-color: var(--main-bg-color);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: lighten;
}
.background {
    height: 100%;
}
.backgroundLighten {
    background-color: var(--main-bg-color);
    background-blend-mode: lighten;
}
.showreelButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    letter-spacing: 0.45ex;
    position: absolute;
}
.showreelButton {

}
.video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    box-sizing: border-box;
}
.videoShowreel {
    width: 100%;
    height: 100%;
}

@media (min-width: 720px) {
    .showreelButtonWrapper {
        right: 240px;
        bottom: 50px;
    }
        .showreelButton {
            font-size: 38px;
            padding: 8px 46px;
        }
    .worksButton {
        display: none;
    }
    .background__desktop {
        position: absolute;
        width: 100%;
        height: 100%;
        mix-blend-mode: lighten; 
        background-color: rgba(0,0,255,0.5);
    }
    .background__mobile_hidden {
        opacity: 0;
        transition: opacity .3s;
    }
}
@media (max-width: 720px) {
    .showreelButtonWrapper {
        font-size: 29px;
        box-sizing: border-box;
        left: 0;
        right: 0;
        bottom: 97px;
    }
        .showreelButton {
            height: 70px;
            width: 100%;
            padding-top: 4px;
            text-align: center;
            line-height: 63px;
        }
    .worksButton {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-decoration: none;
        height: 97px;
        background-color: white;
        font-size: 37px;
        letter-spacing: 0.4ex;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }
    .background__desktop {
        display: none;
    }
}
