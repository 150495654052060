.wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--main-text-color);
    width: 100%;
    height: 100%;
    font-size: 7vw;
    line-height: 1.55em;
    letter-spacing: 2.28px;
    animation-name: out;
    animation-duration: 0.7s;
    transform: translateY(0%) scale(1);
    transform-origin: 30% 50%;
    transition: color 0.2s;
}
.wrapper.isOpen .inner {
    transform: translateY(0%) scale(1.6);
    color: #DEFF8E;
    transition: color 0.2s, transform 1s 0.1s;
    animation: none;
}
.wrapper.noAnimation .inner {
    animation: none;
}
    .top, .middle, .bottom {
        white-space: nowrap;
    }
    .top {
        font-weight: lighter;
    }
    .middle {}
    .bottom {
        font-weight: bold;
    }

@media (min-width: 720px) {
    .wrapper {
        --px-x: 0;
        --px-y: 0;
        --px-distance: 90px;

        transform: translate(0, 0);
        transition: transform 0.2s;
    }
    .wrapper.isOpen {
        transform: translate(
                calc(var(--px-x) * var(--px-distance)),
                calc(var(--px-y) * var(--px-distance))
        );
    }
    .inner {
        margin-left: 269px;
    }
    .wrapper.isInverted .inner {
        color: #ddd;
    }
}
@media (max-width: 1065px) {
    .inner {
        font-size: 6vw;
    }
}
@media (max-width: 905px) {
    .inner {
        font-size: 5vw;
    }
}
@media (max-width: 812px) {
    .inner {
        font-size: 4vw;
    }
}
@media (max-width: 720px) {
    .inner {
        font-size: 80px;
    }
    .wrapper.noAnimation {
        justify-content: flex-start;
        top: 95px;
        right: auto;
        bottom: auto;
        left: 27px;
    }
    .wrapper.noAnimation .inner {
        font-size: 7vmin;
    }
}
@media (max-width: 667px) and (orientation : Landscape) {
    .wrapper.noAnimation .inner {
        font-size: 6vmin;
    }
}
@media (max-width: 640px) and (orientation : Landscape) {
    .wrapper.noAnimation .inner {
        font-size: 6vmin;
    }
}
@media (max-width: 568px) and (orientation : Landscape) {
    .wrapper.noAnimation .inner {
        font-size: 3vmin;
    }
}
@keyframes out {
    0% {
        transform: translate(0%) scale(1.6);
        opacity: 0.5;
    }
    19.9% {
        transform: translate(0%) scale(2);
        opacity: 0;
    }
    20% {
        transform: translate(0%) scale(0.6);
        opacity: 0;
    }
    100% {
        transform: translate(0%) scale(1);
        opacity: 1;
    }
}
