/* .slogan {   
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .3s;
    z-index: -1;
} */
#text2 {
    font-style: italic;
}

@media (min-width: 720px) {
    #text {
        font-size: 41px;
    }
    #text2 {
        font-size: 16px;
    }
}

@media (max-width: 720px) {
    #text {
        font-size: 16px;
    }
    #text2 {
        font-size: 12px;
    }
}