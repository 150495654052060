.worksPage {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--main-bg-color);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: lighten;
}
.backgroundLighten {
    background-color: var(--main-bg-color);
    background-blend-mode: lighten;
}
@media (min-width: 720px) { 
    .worksPage {
        align-items: center;
    }
}