.dots {}
.dot {
    --link-bg-color: white !important;
    padding: 8px;
    cursor: pointer;
    color: var(--main-text-color);
    transition: color var(--main-hover-transition), background-color var(--main-hover-transition);
}
@media (min-width: 720px) {
    .dotMobile {
        display: none;
    }
    .dots {
        display: inline-flex;
        margin-top: 3%;
        margin-left: 65px;
        position: relative;
        z-index: 1;
    }
    .dot {
        font-size: 24px;
        margin-right: 20px;
    }
    .dot:last-child {
        margin-right: 0;
    }
    .dot:hover,
    .dot.active {
        color: #0000ff;
        /*background-color: #fff;*/
    }
}
@media (max-width: 720px) {
    .dots {
        position: relative;
        order: 0;
        flex-shrink: 0;
        letter-spacing: 0.06ex;
        height: 50px;
        mask-image: linear-gradient(
            to right,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,1) 25%,
            rgba(255,255,255,1) 75%,
            rgba(255,255,255,0) 100%
        );
    }
    .dot {
        padding: 8px;
        color: var(--main-text-color);
        font-size: 16px;
        white-space: nowrap;
        position: absolute;
        top: 0;
        transform: translate(-50%, 0);
        transition: left 0.3s, opacity 0.3s, transform 0.3s, mask-image 0.3s;
    }
        .dot { /* hidden dots on the left of the active one */
            left: 0;
            opacity: 0;
            transform: translate(-110%, 0);
        }
        .dot.prev { /* visible dot on the left of the active one */
            left: 0;
            opacity: 0.6;
            transform: translate(-50%, 0);
        }
        .dot.active {
            left: 50%;
            opacity: 1;
            transform: translate(-50%, 0);
        }
        .dot.active + .dot { /* visible dot on the right of the active one */
            left: 100%;
            opacity: 0.6;
            transform: translate(-40%, 0);
        }
        .dot.active + .dot ~ .dot { /* hidden dots on the right of the active one */
            left: 100%;
            opacity: 0;
            transform: translate(0%, 0);
        }
}
