.cursor {
    width: 9px;
    height: 9px;
    margin: -5px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    transition: width 0.3s, background-color 0.3s, transform 0.3s;
}
.cursor::before,
.cursor::after {
    display: block;
    content: '';
}
.cursor::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0);
    transform: scale(4);
    transform-origin: center center;
    transition: background-color 0.2s, transform 0.2s;
}

.cursor[data-type="link"] {}
.cursor[data-type="link"]::after {
    background-color: rgba(255, 0, 0, 0.3);
    transform: scale(3);
    transition: background-color 0.5s, transform 0.5s;
}
.cursor[data-type="next"],
.cursor[data-type="prev"],
.cursor[data-type="nextWork"],
.cursor[data-type="nextWorkBlackArrow"] {
    background-color: transparent;
    width: 80px;
    height: 10px;
    transform: translateX(-50%);
    transition: width 0.3s, background-color 0.3s, transform 0.3s;
}
.cursor[data-type="next"]::after,
.cursor[data-type="prev"]::after,
.cursor[data-type="nextWork"]::after {
    transform: scale(1);
    opacity: 0;
    background: url(./icons/arrow.svg) 50% 50%/contain no-repeat;
    transition: background 0.3s;
    animation: fadeInArrow .15s .1s;
    animation-fill-mode: forwards;
}
.cursor[data-type="prev"]::after {
    transform: scale(1) rotate(180deg);
}
.cursor[data-type="nextWork"]::after {
    background: url(./icons/arrow__green.svg) 50% 50%/contain no-repeat;
}
@keyframes fadeInArrow {
    from {opacity: 0;}
    to {opacity: 1;}
}
.cursor[data-type="nextWorkBlackArrow"]::after {
    transform: scale(1);
    background: url(./icons/arrow__black.svg) 50% 50%/contain no-repeat;
}