@font-face {
    font-family: MuseoSansCyrl;
    src: url('./MuseoSansCyrl_Black/MuseoSansCyrl_Black.eot') format('eot'),
    url('./MuseoSansCyrl_Black/MuseoSansCyrl_Black.woff') format('woff'),
    url('./MuseoSansCyrl_Black/MuseoSansCyrl_Black.otf') format('otf'),
    url('./MuseoSansCyrl_Black/MuseoSansCyrl_Black.ttf') format('ttf'),
    url('./MuseoSansCyrl_Black/MuseoSansCyrl_Black.svg#MuseoSansCyrl_Black') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: MuseoSansCyrl;
    src: url('./MuseoSansCyrl_Regular/MuseoSansCyrl_Regular.otf') format('otf'),
    url('./MuseoSansCyrl_Regular/MuseoSansCyrl_Regular.woff') format('woff'),
    url('./MuseoSansCyrl_Regular/MuseoSansCyrl_Regular.svg#MuseoSansCyrl_Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: MuseoSansCyrl;
    src: url('./MuseoSansCyrl_Thin/MuseoSansCyrl_Thin.otf') format('otf'),
    url('./MuseoSansCyrl_Thin/MuseoSansCyrl_Thin.woff') format('woff'),
    url('./MuseoSansCyrl_Thin/MuseoSansCyrl_Thin.svg#MuseoSansCyrl_Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}
