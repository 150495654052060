.siteMapMenu {
    display: flex;
    flex-direction: column;
    line-height: 1.35em;
    position: relative;
}
.link {
    text-decoration: none;
    color: #000;
}
.item + .item {
    margin-top: 1em;
}

@media (min-width: 720px) {
    .siteMapMenu {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 269px;
        font-size: 72px;
    }
}
@media (max-width: 720px) {
    .siteMapMenu {
        justify-content: center;
        padding-left: 12vw;
        font-size: 92px;
    }
}
@media (max-width: 470px) {
    .siteMapMenu {
        font-size: 58px;
    }
}
