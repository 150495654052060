.menu {
    display: inline-block;
}
.link {
    text-decoration: none;
    color: var(--main-link-color);
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: 0;
    opacity: 1;
    transition: background-color var(--main-hover-transition), color var(--main-hover-transition), top 0s 1.5s, opacity 0s 1.5s;
}
    .link.hidden {
        top: -20px;
        opacity: 0;
        pointer-events: none;
    }
.link.detached {
    position: fixed;
    top: 0;
    right: 0;
    width: 190px;
    height: 100%;
    padding-top: 93px;
    text-align: center;
    color: #000;
    background-color: #fff;
    transition: background-color var(--main-hover-transition), right 0s 1.5s, opacity 0s 1.5s;
}
.link.detached:hover {
    background-color: var(--main-hover-bgColor);
}
    .link.detached.hidden {
        right: -190px;
        opacity: 1;
    }
.link:first-child {
    margin-right: 65px;
}
.link:hover  {
    color: var(--main-hover-color);
}
