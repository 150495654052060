.isWorks .mobileLogo {
    display: none;
}

@media (max-width: 720px) {
    .isWorks .detailWorksLogo {
        display: none;
    }
    .isWorks .mobileLogo {
        display: block;
        position: absolute;
        top: 22px;
        left: 27px;
        width: 49px;
    }
    .isWorks .detailWorksHamburger {
        padding: 5px 10px;
        margin: 20px 10px 0 0;
        background-color: #fff;
        transition: padding-top .7s, padding-bottom .7s, background-color 3.5s;
    }
    .isWorks .detailWorksHamburger span:first-child,
    .isWorks .detailWorksHamburger span:last-child {
        background-color: #000;
    }
    .isWorks .detailWorksHamburger span:first-child {
        margin-bottom: 5px;
    }
    .isWorks .detailWorksHamburger span:last-child {
        margin-top: 5px;
    }
    .isWorks .detailWorksHamburger.isOpen {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .isWorks .detailWorksHamburger.isOpen:hover {
        background-color: var(--main-hover-bgColor);
        transition: padding-top .7s, padding-bottom .7s, background-color .5s;
    }
}