html,
body {
    height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: MuseoSansCyrl, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
    height: 100%;
    --main-bg-color: #141414;
    --main-link-color: #fff;
    --main-text-color: #fff;
    --main-hover-color: #000;
    --main-hover-bgColor: #c6ff00;
    --main-hover-transition: .3s;
    --sidebar-width: 190px;
}

/* prevent ios bouncing scroll */
@media (max-width: 720px) {
    html {
        height: 100%;
        overflow: hidden;
    }
    body {
        height: 100%;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}


.fade-enter {}

.fade-enter-active {
    z-index: 0;
}

.fade-exit {}

.fade-exit-active {
    z-index: 1;
}

.slogan-enter-active {
    opacity: 1 !important;
    z-index: 9999 !important;
}
.slogan-enter-done {
    opacity: 1 !important;
    z-index: 9999 !important;
}
.slogan-exit-active {
    opacity: 0 !important;
    z-index: 9999 !important;
}
.slogan-exit-done {
    opacity: 0 !important;
    z-index: -1 !important;
}
.tagline_wrapper__2b88L {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.tagline_inner__2iFFg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color: var(--main-text-color);
    width: 100%;
    height: 100%;
    font-size: 7vw;
    line-height: 1.55em;
    letter-spacing: 2.28px;
    -webkit-animation-name: tagline_out__24bRy;
            animation-name: tagline_out__24bRy;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-transform: translateY(0%) scale(1);
            transform: translateY(0%) scale(1);
    -webkit-transform-origin: 30% 50%;
            transform-origin: 30% 50%;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}
.tagline_wrapper__2b88L.tagline_isOpen__QqC1p .tagline_inner__2iFFg {
    -webkit-transform: translateY(0%) scale(1.6);
            transform: translateY(0%) scale(1.6);
    color: #DEFF8E;
    -webkit-transition: color 0.2s, -webkit-transform 1s 0.1s;
    transition: color 0.2s, -webkit-transform 1s 0.1s;
    transition: color 0.2s, transform 1s 0.1s;
    transition: color 0.2s, transform 1s 0.1s, -webkit-transform 1s 0.1s;
    -webkit-animation: none;
            animation: none;
}
.tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 .tagline_inner__2iFFg {
    -webkit-animation: none;
            animation: none;
}
    .tagline_top__155w2, .tagline_middle__2KamN, .tagline_bottom__Ty9LV {
        white-space: nowrap;
    }
    .tagline_top__155w2 {
        font-weight: lighter;
    }
    .tagline_middle__2KamN {}
    .tagline_bottom__Ty9LV {
        font-weight: bold;
    }

@media (min-width: 720px) {
    .tagline_wrapper__2b88L {
        --px-x: 0;
        --px-y: 0;
        --px-distance: 90px;

        -webkit-transform: translate(0, 0);

                transform: translate(0, 0);
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
    }
    .tagline_wrapper__2b88L.tagline_isOpen__QqC1p {
        -webkit-transform: translate(
                calc(var(--px-x) * var(--px-distance)),
                calc(var(--px-y) * var(--px-distance))
        );
                transform: translate(
                calc(var(--px-x) * var(--px-distance)),
                calc(var(--px-y) * var(--px-distance))
        );
    }
    .tagline_inner__2iFFg {
        margin-left: 269px;
    }
    .tagline_wrapper__2b88L.tagline_isInverted__1xXZN .tagline_inner__2iFFg {
        color: #ddd;
    }
}
@media (max-width: 1065px) {
    .tagline_inner__2iFFg {
        font-size: 6vw;
    }
}
@media (max-width: 905px) {
    .tagline_inner__2iFFg {
        font-size: 5vw;
    }
}
@media (max-width: 812px) {
    .tagline_inner__2iFFg {
        font-size: 4vw;
    }
}
@media (max-width: 720px) {
    .tagline_inner__2iFFg {
        font-size: 80px;
    }
    .tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        top: 95px;
        right: auto;
        bottom: auto;
        left: 27px;
    }
    .tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 .tagline_inner__2iFFg {
        font-size: 7vmin;
    }
}
@media (max-width: 667px) and (orientation : Landscape) {
    .tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 .tagline_inner__2iFFg {
        font-size: 6vmin;
    }
}
@media (max-width: 640px) and (orientation : Landscape) {
    .tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 .tagline_inner__2iFFg {
        font-size: 6vmin;
    }
}
@media (max-width: 568px) and (orientation : Landscape) {
    .tagline_wrapper__2b88L.tagline_noAnimation__1EVT7 .tagline_inner__2iFFg {
        font-size: 3vmin;
    }
}
@-webkit-keyframes tagline_out__24bRy {
    0% {
        -webkit-transform: translate(0%) scale(1.6);
                transform: translate(0%) scale(1.6);
        opacity: 0.5;
    }
    19.9% {
        -webkit-transform: translate(0%) scale(2);
                transform: translate(0%) scale(2);
        opacity: 0;
    }
    20% {
        -webkit-transform: translate(0%) scale(0.6);
                transform: translate(0%) scale(0.6);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0%) scale(1);
                transform: translate(0%) scale(1);
        opacity: 1;
    }
}
@keyframes tagline_out__24bRy {
    0% {
        -webkit-transform: translate(0%) scale(1.6);
                transform: translate(0%) scale(1.6);
        opacity: 0.5;
    }
    19.9% {
        -webkit-transform: translate(0%) scale(2);
                transform: translate(0%) scale(2);
        opacity: 0;
    }
    20% {
        -webkit-transform: translate(0%) scale(0.6);
                transform: translate(0%) scale(0.6);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0%) scale(1);
                transform: translate(0%) scale(1);
        opacity: 1;
    }
}

.index_index__1xag7 {
    width: 100%;
    height: 100%;
    background-color: var(--main-bg-color);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: lighten;
}
.index_background__1J5k5 {
    height: 100%;
}
.index_backgroundLighten__lpFQB {
    background-color: var(--main-bg-color);
    background-blend-mode: lighten;
}
.index_showreelButtonWrapper__x6nLu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: black;
    letter-spacing: 0.45ex;
    position: absolute;
}
.index_showreelButton__2Blij {

}
.index_video__1ml43 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    box-sizing: border-box;
}
.index_videoShowreel__apnri {
    width: 100%;
    height: 100%;
}

@media (min-width: 720px) {
    .index_showreelButtonWrapper__x6nLu {
        right: 240px;
        bottom: 50px;
    }
        .index_showreelButton__2Blij {
            font-size: 38px;
            padding: 8px 46px;
        }
    .index_worksButton__22huf {
        display: none;
    }
    .index_background__desktop__2IQ4Y {
        position: absolute;
        width: 100%;
        height: 100%;
        mix-blend-mode: lighten; 
        background-color: rgba(0,0,255,0.5);
    }
    .index_background__mobile_hidden__2lkzk {
        opacity: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
    }
}
@media (max-width: 720px) {
    .index_showreelButtonWrapper__x6nLu {
        font-size: 29px;
        box-sizing: border-box;
        left: 0;
        right: 0;
        bottom: 97px;
    }
        .index_showreelButton__2Blij {
            height: 70px;
            width: 100%;
            padding-top: 4px;
            text-align: center;
            line-height: 63px;
        }
    .index_worksButton__22huf {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-decoration: none;
        height: 97px;
        background-color: white;
        font-size: 37px;
        letter-spacing: 0.4ex;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        color: black;
    }
    .index_background__desktop__2IQ4Y {
        display: none;
    }
}

.popup_wrapper__1PxRd {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background-color: black;
    -webkit-animation-name: popup_in__3cZ7U;
            animation-name: popup_in__3cZ7U;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}
.popup_content__cx73p {
    width: 100%;
    height: 100%;
}
.popup_close__12ezy {
    background-color: var(--main-hover-bgColor);
    width: 40px;
    height: 40px;
    position: fixed;
    top: 40px;
    right: 40px;
    border: 10px solid var(--main-hover-bgColor);
    z-index: 1; 
}
.popup_inner__1eW_S {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}
.popup_inner__1eW_S:hover {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
}
.popup_inner__1eW_S::before,
.popup_inner__1eW_S::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: black;
}
.popup_inner__1eW_S::before {
    -webkit-transform: translate(0, 20px) scale(1.41) rotate(45deg);
            transform: translate(0, 20px) scale(1.41) rotate(45deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
.popup_inner__1eW_S::after {
    -webkit-transform: translate(0, 20px) scale(1.41) rotate(-45deg);
            transform: translate(0, 20px) scale(1.41) rotate(-45deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}

@-webkit-keyframes popup_in__3cZ7U {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes popup_in__3cZ7U {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.cursor_cursor__C7HkQ {
    width: 9px;
    height: 9px;
    margin: -5px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    -webkit-transition: width 0.3s, background-color 0.3s, -webkit-transform 0.3s;
    transition: width 0.3s, background-color 0.3s, -webkit-transform 0.3s;
    transition: width 0.3s, background-color 0.3s, transform 0.3s;
    transition: width 0.3s, background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.cursor_cursor__C7HkQ::before,
.cursor_cursor__C7HkQ::after {
    display: block;
    content: '';
}
.cursor_cursor__C7HkQ::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0);
    -webkit-transform: scale(4);
            transform: scale(4);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: background-color 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, transform 0.2s;
    transition: background-color 0.2s, transform 0.2s, -webkit-transform 0.2s;
}

.cursor_cursor__C7HkQ[data-type="link"] {}
.cursor_cursor__C7HkQ[data-type="link"]::after {
    background-color: rgba(255, 0, 0, 0.3);
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transition: background-color 0.5s, -webkit-transform 0.5s;
    transition: background-color 0.5s, -webkit-transform 0.5s;
    transition: background-color 0.5s, transform 0.5s;
    transition: background-color 0.5s, transform 0.5s, -webkit-transform 0.5s;
}
.cursor_cursor__C7HkQ[data-type="next"],
.cursor_cursor__C7HkQ[data-type="prev"],
.cursor_cursor__C7HkQ[data-type="nextWork"],
.cursor_cursor__C7HkQ[data-type="nextWorkBlackArrow"] {
    background-color: transparent;
    width: 80px;
    height: 10px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: width 0.3s, background-color 0.3s, -webkit-transform 0.3s;
    transition: width 0.3s, background-color 0.3s, -webkit-transform 0.3s;
    transition: width 0.3s, background-color 0.3s, transform 0.3s;
    transition: width 0.3s, background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.cursor_cursor__C7HkQ[data-type="next"]::after,
.cursor_cursor__C7HkQ[data-type="prev"]::after,
.cursor_cursor__C7HkQ[data-type="nextWork"]::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
    background: url(/static/media/arrow.55147472.svg) 50% 50%/contain no-repeat;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    -webkit-animation: cursor_fadeInArrow__2n3j9 .15s .1s;
            animation: cursor_fadeInArrow__2n3j9 .15s .1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.cursor_cursor__C7HkQ[data-type="prev"]::after {
    -webkit-transform: scale(1) rotate(180deg);
            transform: scale(1) rotate(180deg);
}
.cursor_cursor__C7HkQ[data-type="nextWork"]::after {
    background: url(/static/media/arrow__green.b9db9e55.svg) 50% 50%/contain no-repeat;
}
@-webkit-keyframes cursor_fadeInArrow__2n3j9 {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes cursor_fadeInArrow__2n3j9 {
    from {opacity: 0;}
    to {opacity: 1;}
}
.cursor_cursor__C7HkQ[data-type="nextWorkBlackArrow"]::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    background: url(/static/media/arrow__black.7cc3e9aa.svg) 50% 50%/contain no-repeat;
}
.linkbg_wrapper__2tgjm {
    --link-bg-color: var(--main-hover-bgColor);
    position: relative;
    color: black;
    text-decoration: none;
    z-index: 0;
}
    .linkbg_bg__Zq1pR {
        background-color: var(--link-bg-color);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        -webkit-transform: translate(0);
                transform: translate(0);
        -webkit-transition: opacity var(--main-hover-transition), -webkit-transform 0.3s;
        transition: opacity var(--main-hover-transition), -webkit-transform 0.3s;
        transition: opacity var(--main-hover-transition), transform 0.3s;
        transition: opacity var(--main-hover-transition), transform 0.3s, -webkit-transform 0.3s;
    }
    .linkbg_wrapper__2tgjm:hover .linkbg_bg__Zq1pR {
        -webkit-transition: opacity var(--main-hover-transition), -webkit-transform 0s;
        transition: opacity var(--main-hover-transition), -webkit-transform 0s;
        transition: opacity var(--main-hover-transition), transform 0s;
        transition: opacity var(--main-hover-transition), transform 0s, -webkit-transform 0s;
        opacity: 1;
    }
    .linkbg_wrapper__2tgjm.linkbg_isAlwaysShown__2wjRp .linkbg_bg__Zq1pR {
        -webkit-transition: -webkit-transform 0s;
        transition: -webkit-transform 0s;
        transition: transform 0s;
        transition: transform 0s, -webkit-transform 0s;
        opacity: 1;
    }
@media (max-width: 720px) {
    .linkbg_wrapper__2tgjm.linkbg_noMobileBG__1fi53 .linkbg_bg__Zq1pR {
        background-color: transparent;
    }

    .linkbg_bg__Zq1pR {
        -webkit-transform: translate(0) !important;
                transform: translate(0) !important;
    }
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}
.background-base {
    opacity: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.background-in {
    -webkit-animation-name: background-in;
            animation-name: background-in;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}
.background-out {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation-name: background-out;
            animation-name: background-out;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}

@-webkit-keyframes background-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes background-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes background-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes background-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.splitPanes_panes__f29Ol {
    width: 100%;
    height: 100%;
}
.splitPanes_pane__2pyXv {
    overflow: auto;
}
.splitPanes_first__cBBUM {
    background-color: var(--main-bg-color);
}
.splitPanes_second__2ei-b {
    background-color: white;
}

@media (min-width: 720px) {
    .splitPanes_panes__f29Ol {
        --offset: 0;

        display: -webkit-flex;

        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
    }
    .splitPanes_scrollable__2QsA- {
        width: 100%;
        height: 100%;
        display: -webkit-flex;
        display: flex;
    }
    .splitPanes_pane__2pyXv {
        width: 50%;
        -webkit-transition: width 0.1s;
        transition: width 0.1s;
    }
    .splitPanes_panes__f29Ol.splitPanes_catched__XFdtb .splitPanes_pane__2pyXv {
        -webkit-transition: none;
        transition: none;
    }
    .splitPanes_first__cBBUM {
        width: calc(50% + var(--offset) * 1px);
    }
    .splitPanes_first__cBBUM.splitPanes_fixed__qKM7a {
        display: none;
    }
    .splitPanes_second__2ei-b {
        position: relative;
        width: calc(50% - var(--offset) * 1px);
    }

    .splitPanes_handle__3fFJl {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 51px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        z-index: 1;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        background-color: white;
    }
    .splitPanes_handle__3fFJl::before {
        display: block;
        content: '';
        width: 5px;
        height: 80px;
        border-radius: 3px;
        background-color: #ececec;
    }

    .splitPanes_panes__f29Ol.splitPanes_collapsed__2AgPB .splitPanes_pane__2pyXv.splitPanes_first__cBBUM {
        width: calc(100% - 51px + var(--offset) * 1px);
    }
    .splitPanes_panes__f29Ol.splitPanes_collapsed__2AgPB .splitPanes_pane__2pyXv.splitPanes_second__2ei-b {
        width: calc(51px - var(--offset) * 1px);
    }
}
@media (max-width: 720px) {
    .splitPanes_panes__f29Ol {
        position: relative;
    }
    .splitPanes_first__cBBUM {
        position: fixed;
        top: 0;
        height: 70vh;
        width: 100%;
        opacity: 0;
    }
    .splitPanes_first__cBBUM:hover {
        opacity: 1;
    }
    .splitPanes_first__cBBUM.splitPanes_fixed__qKM7a {
        opacity: 1;
    }
    .splitPanes_scrollable__2QsA- {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        padding-top: 70vh;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .splitPanes_second__2ei-b {
        padding-top: calc(15px * 2 + 7px);
        position: relative;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 30px;
    }
    .splitPanes_second__2ei-b::before {
        display: block;
        content: '';
        width: 94px;
        height: 7px;
        background-color: #EBEBEB;
        border-radius: 4px;
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.aboutPage_wrapper__3h7gY {
    width: 100%;
    height: 100%;
}
.aboutPage_inner__1p9uo {
    width: 100%;
    min-height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-sizing: border-box;
}
.aboutPage_title__3I53D,
.aboutPage_text__2iJ9H {
    color: var(--main-text-color);
}
.aboutPage_title__3I53D {}
.aboutPage_text__2iJ9H {
    font-weight: lighter;
}
.aboutPage_labels__1oJr4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.aboutPage_label__31zgm {
    display: -webkit-flex;
    display: flex;
    width: 35%;
    height: 125px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
    background-blend-mode: lighten;
}
.aboutPage_desktop-image__b5tKG {
    width: 100%;
}
.aboutPage_top__1EVVI {}
.aboutPage_bottom__1FJGV {}

@media (min-width: 720px) {
    .aboutPage_inner__1p9uo {
        padding-top: 240px;
        padding-bottom: 40px;
    }
    .aboutPage_first__2gsWj {
        padding-right: calc(20px + 10%);
        padding-left: calc(var(--sidebar-width) + 8%);
    }
    .aboutPage_second__1f987 {
        padding-right: calc(var(--sidebar-width) + 8%);
        padding-left: calc(20px + 10%);
    }

    .aboutPage_title__3I53D,
    .aboutPage_text__2iJ9H {
        font-size: 35px;
    }
    .aboutPage_title__3I53D {
        margin-bottom: 36px;
    }
    .aboutPage_text__2iJ9H {
        margin-bottom: 72px;
    }

    .aboutPage_top__1EVVI {
        font-size: 24px;
        margin-bottom: 78px;
    }
    .aboutPage_bottom__1FJGV {
        font-size: 16px;
    }
    .aboutPage_top__1EVVI .aboutPage_item__3vbpj + .aboutPage_item__3vbpj {
        margin-top: 49px;
    }
    .aboutPage_bottom__1FJGV .aboutPage_item__3vbpj + .aboutPage_item__3vbpj {
        margin-top: 59px;
    }
    .aboutPage_dots__3NZBA {
        display: none;
    }
}
@media (max-width: 720px) {
    .aboutPage_inner__1p9uo {
    }
    .aboutPage_first__2gsWj {
        padding: 107px 10% 0;
        overflow: hidden;
    }

    .aboutPage_title__3I53D,
    .aboutPage_text__2iJ9H {
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.1ex;
    }
    .aboutPage_text__2iJ9H {
        margin-top: 0.7em;
        margin-bottom: 1.5em;
    }

    .aboutPage_top__1EVVI {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 2em;
        text-align: center;
    }
    .aboutPage_bottom__1FJGV {
        font-size: 16px;
        text-align: center;
    }

    .aboutPage_top__1EVVI .aboutPage_item__3vbpj + .aboutPage_item__3vbpj {
        margin-top: 1.5em;
    }
    .aboutPage_bottom__1FJGV .aboutPage_item__3vbpj + .aboutPage_item__3vbpj {
        margin-top: 1.7em;
    }
    .aboutPage_labels__1oJr4 {
        --slider-slide-number: 0;
        --slider-drag-offset: 0;
        
        -webkit-flex-wrap: nowrap;
        
                flex-wrap: nowrap;
        padding-bottom: 20px;
        -webkit-transform: translate(calc(var(--slider-slide-number) * -100% + var(--slider-drag-offset) * 1px));
                transform: translate(calc(var(--slider-slide-number) * -100% + var(--slider-drag-offset) * 1px));
        -webkit-transition: -webkit-transform .1s;
        transition: -webkit-transform .1s;
        transition: transform .1s;
        transition: transform .1s, -webkit-transform .1s;
    }
    .aboutPage_label__31zgm {
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        width: 70%;
        height: 90px;
        margin: 0 15%;
    }
    .aboutPage_dots__3NZBA {
        width: 100%;
        height: 11px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .aboutPage_dot__2kggc {
        width: 11px;
        height: 11px;
        margin-right: 20px;
        background-color: #fff;
        -webkit-transition: background-color var(--main-hover-transition);
        transition: background-color var(--main-hover-transition);
    }
    .aboutPage_dotActive__2Dd0M {
        background-color: var(--main-hover-bgColor);
    }
    .aboutPage_dot__2kggc:last-child {
        margin-right: 0;
    }
}

.contacts_wrapper__3xEi6 {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    background-color: var(--main-bg-color);
}
.contacts_map__3Tue3 {
    height: 100%;
}
.contacts_contacts__3HUre {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    background-color: #fff;
}
.contacts_info__1kB-A {}
.contacts_top__3v5aT {}
.contacts_middle__12ATr {
    font-weight: lighter;
}
.contacts_info__1kB-A p {
    margin-top: 0;
    margin-bottom: 0;
}

.contacts_bottom__3O7Qo {
    font-weight: lighter;
}
.contacts_contact__2N10I {
    display: block;
    margin-bottom: 25px;
    text-decoration: none;
    color: #000;
}
.contacts_contact__2N10I:last-child {
    margin-bottom: 0;
}

@media (min-width: 720px) {
    .contacts_wrapper__3xEi6 {
        padding-left: 20%;
    }
    .contacts_contacts__3HUre {
        padding-right: calc(20px + 10%);
        padding-left: calc(20px + 10%);
    }

    .contacts_info__1kB-A {
        font-size: 36px;
    }
    .contacts_top__3v5aT {
        margin-bottom: 80px;
    }
    .contacts_middle__12ATr {
        margin-bottom: 60px;
    }
    .contacts_bottom__3O7Qo {
        font-size: 24px;
    }

    .contacts_info__1kB-A p + p {
        margin-top: 0.7em;
    }
}
@media (max-width: 720px) {
    .contacts_info__1kB-A {
        font-size: 22px;
        width: 100%;
    }

    .contacts_top__3v5aT {
        margin-bottom: 1em;
    }
    .contacts_middle__12ATr {
        margin-bottom: 1em;
    }

    .contacts_info__1kB-A p + p {
        margin-top: 0.2em;
    }
}

.slider_slider__2Kd-e {
     --slider-slide-number: 0;
    
    width: 100%;
    height: 60%;
    box-sizing: border-box;
}
.slider_slider__2Kd-e::after {
    content: '';
    display: block;
    width: 100%;
    height: 15px;
    position: absolute;
    top: 70%;
    left: 0;
    background-color: var(--main-hover-bgColor);
}
.slider_outer__2rrJ0 { 
    --slider-drag-offset: 0;
    
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    z-index: 1;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
}
.slider_inner__1150L {
    width: 65%;
    height: 100%;
    padding-bottom: 30px;
    margin: 0 auto;
}
.slider_content__3wr33 {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-transform: translate(calc(var(--slider-slide-number) * -110% + var(--slider-drag-offset) * 1px));
            transform: translate(calc(var(--slider-slide-number) * -110% + var(--slider-drag-offset) * 1px));
    -webkit-transition: -webkit-transform var(--main-hover-transition);
    transition: -webkit-transform var(--main-hover-transition);
    transition: transform var(--main-hover-transition);
    transition: transform var(--main-hover-transition), -webkit-transform var(--main-hover-transition);
}
.slider_slide__2I894 {
    width: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-transition: -webkit-transform var(--main-hover-transition);
    transition: -webkit-transform var(--main-hover-transition);
    transition: transform var(--main-hover-transition);
    transition: transform var(--main-hover-transition), -webkit-transform var(--main-hover-transition);
}
.slider_slide__2I894.slider_current__1swxg,
.slider_slide__2I894.slider_current__1swxg ~ .slider_slide__2I894 {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slider_desktop-image__fkrlM {
    height: 100%;
}
.slider_prev__39S83,
.slider_next__u1euq{
    width: 25%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
}
.slider_prev__39S83 {
    left: 0;
}
.slider_next__u1euq {
    right: 0;
}
@media (min-width: 720px) {
    .slider_slide__2I894 {
        -webkit-transform: translate(-25%, 0);
                transform: translate(-25%, 0);
        box-sizing: border-box;
        padding-right: 10%;
    }
    .slider_slide__2I894 + .slider_slide__2I894 { 
        margin-left: 10%; 
    }
}
@media (max-width: 720px) {
    .slider_slider__2Kd-e {
        height: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 24vh;
        padding-bottom: 5vw;
    }
    .slider_slider__2Kd-e::after {
        bottom: auto;
        top: 50%;
    }
    .slider_outer__2rrJ0 {
        -webkit-order: 1;
                order: 1;
        padding-bottom: 0;
        overflow: hidden;
    }
    .slider_inner__1150L {
        width: 90%;
    }
    .slider_slide__2I894 + .slider_slide__2I894 { 
        margin-left: 10%; 
    }
    .slider_next__u1euq,
    .slider_prev__39S83 {
        display: none;
    }
}

.slide_wrapper__2xn58 {
    height: 100%;
}
    .slide_wrapper__2xn58.slide_animated__2sUbZ {
        position: absolute;
        z-index: 1;
    }
        .slide_wrapper__2xn58.slide_animated__2sUbZ .slide_image__3Boko {
            padding-bottom: 0;
            height: 100%;
        }
        .slide_wrapper__2xn58.slide_animated__2sUbZ .slide_layout__2s2-9 {
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: black;
            position: absolute;
            top: 0;
            left: 0;
        }
.slide_wrapper__2xn58.slide_hidden__3SEBc {
    visibility: hidden;
}

.slide_inner__2LAvU {
    width: 100%;
    height: 100%;
}
.slide_button__2sOnh {
    width: 197px;
    height: 65px;
    position: absolute;
    right: calc(-10% + 195px);
    bottom: -87px;
    box-sizing: border-box;
    color: var(--main-hover-color);
    /*background-color: var(--main-hover-bgColor);*/
    text-align: center;
    font-size: 40px;
    line-height: 30px;
    cursor: pointer;
}
.slide_title__2hJWq {
    color: var(--main-text-color);
    font-size: 81px;
    white-space: pre-wrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.slide_image__3Boko,
.slide_mobileImage__22O1N {
    background-position: center;
    background-size: cover;
    width: 100%;
    display: none;
}

@media (min-width: 720px) {
    .slide_inner__2LAvU {
        position: relative;
    }
    .slide_image__3Boko {
        display: block;
        height: 100%;
    }
    .slide_title__2hJWq {
        position: absolute;
        top: 50%;
        right: calc(-10% - 50px);
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
}
@media (max-width: 720px) {
    .slide_wrapper__2xn58 {
        height: 100%;
        width: 100%;
        padding: 17px;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
                align-items: flex-end;
    }
    .slide_inner__2LAvU {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
    .slide_button__2sOnh {
        -webkit-order: 1;
                order: 1;
        width: 100%;
        height: 64px;
        right: auto;
        position: relative;
        bottom: auto;
        -webkit-transform: none;
                transform: none;
        font-size: 41px;
        letter-spacing: 0.1ex;
    }
    .slide_title__2hJWq {
        -webkit-order: 0;
                order: 0;
        position: relative;
        font-size: 29px;
        line-height: 1;
        letter-spacing: 0.1ex;
        margin: 0 3px 13px;
    }
    .slide_mobileImage__22O1N {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
    }
}

.button_button__Nyhu_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.button_text__3j5o5 {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.dots_dots__3TswK {}
.dots_dot__1gj1b {
    --link-bg-color: white !important;
    padding: 8px;
    cursor: pointer;
    color: var(--main-text-color);
    -webkit-transition: color var(--main-hover-transition), background-color var(--main-hover-transition);
    transition: color var(--main-hover-transition), background-color var(--main-hover-transition);
}
@media (min-width: 720px) {
    .dots_dotMobile__17Xhy {
        display: none;
    }
    .dots_dots__3TswK {
        display: -webkit-inline-flex;
        display: inline-flex;
        margin-top: 3%;
        margin-left: 65px;
        position: relative;
        z-index: 1;
    }
    .dots_dot__1gj1b {
        font-size: 24px;
        margin-right: 20px;
    }
    .dots_dot__1gj1b:last-child {
        margin-right: 0;
    }
    .dots_dot__1gj1b:hover,
    .dots_dot__1gj1b.dots_active__2sxdn {
        color: #0000ff;
        /*background-color: #fff;*/
    }
}
@media (max-width: 720px) {
    .dots_dots__3TswK {
        position: relative;
        -webkit-order: 0;
                order: 0;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        letter-spacing: 0.06ex;
        height: 50px;
        -webkit-mask-image: -webkit-linear-gradient(
            left,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,1) 25%,
            rgba(255,255,255,1) 75%,
            rgba(255,255,255,0) 100%
        );
                mask-image: linear-gradient(
            to right,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,1) 25%,
            rgba(255,255,255,1) 75%,
            rgba(255,255,255,0) 100%
        );
    }
    .dots_dot__1gj1b {
        padding: 8px;
        color: var(--main-text-color);
        font-size: 16px;
        white-space: nowrap;
        position: absolute;
        top: 0;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        -webkit-transition: left 0.3s, opacity 0.3s, -webkit-transform 0.3s, -webkit-mask-image 0.3s;
        transition: left 0.3s, opacity 0.3s, -webkit-transform 0.3s, -webkit-mask-image 0.3s;
        transition: left 0.3s, opacity 0.3s, transform 0.3s, mask-image 0.3s;
        transition: left 0.3s, opacity 0.3s, transform 0.3s, mask-image 0.3s, -webkit-transform 0.3s, -webkit-mask-image 0.3s;
    }
        .dots_dot__1gj1b { /* hidden dots on the left of the active one */
            left: 0;
            opacity: 0;
            -webkit-transform: translate(-110%, 0);
                    transform: translate(-110%, 0);
        }
        .dots_dot__1gj1b.dots_prev__3PRIb { /* visible dot on the left of the active one */
            left: 0;
            opacity: 0.6;
            -webkit-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
        }
        .dots_dot__1gj1b.dots_active__2sxdn {
            left: 50%;
            opacity: 1;
            -webkit-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
        }
        .dots_dot__1gj1b.dots_active__2sxdn + .dots_dot__1gj1b { /* visible dot on the right of the active one */
            left: 100%;
            opacity: 0.6;
            -webkit-transform: translate(-40%, 0);
                    transform: translate(-40%, 0);
        }
        .dots_dot__1gj1b.dots_active__2sxdn + .dots_dot__1gj1b ~ .dots_dot__1gj1b { /* hidden dots on the right of the active one */
            left: 100%;
            opacity: 0;
            -webkit-transform: translate(0%, 0);
                    transform: translate(0%, 0);
        }
}

.works_worksPage__3YEgj {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--main-bg-color);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: lighten;
}
.works_backgroundLighten__cOIo4 {
    background-color: var(--main-bg-color);
    background-blend-mode: lighten;
}
@media (min-width: 720px) { 
    .works_worksPage__3YEgj {
        -webkit-align-items: center;
                align-items: center;
    }
}
.detail_detail__2V1FF {
    --vertical-offset: 0;

    width: 100%;
    height: 100%;
}
.detail_background__1zE9f {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
}
.detail_backgroundDark__33MlU::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .5);
}
.detail_sideBar__J-OoJ {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}
.detail_first__1ydZB {
    width: 100%;
    position: relative;
    padding-left: 20%;
    padding-right: var(--sidebar-width);
    overflow-y: auto;
}
.detail_wrapper__3doMb {
    width: 100%;
    /*height: 100%;*/
    position: relative;
}
.detail_outer__2n1RS {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    box-sizing: border-box;
}
.detail_info__2Q-hy {
    width: 100%;
    color: var(--main-text-color);
}
.detail_title__g0r2D,
.detail_text__o3FcX {
    white-space: pre-wrap;
}
.detail_title__g0r2D {
    font-size: 81px;
    margin-bottom: 48px;
}
.detail_text__o3FcX {
    width: 589px;
    font-size: 18px;
}
.detail_close__3L0GA{
    width: 197px;
    height: 65px;
    position: fixed;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    top: 50%;
    right: 10%;
    -webkit-transform: translate(-60%, -50%);
            transform: translate(-60%, -50%);
    color: var(--main-hover-color);
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
    font-size: 40px;
    line-height: 29px;
    cursor: pointer;
}
.detail_second__33k3h {
    width: var(--sidebar-width);
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
}
.detail_link__BEayW {
    display: block;
    width: 100%;
    height: 100%;
}
.detail_next__2v9lE {
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.detail_inner__2XSZM {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-transition: background-color var(--main-hover-transition);
    transition: background-color var(--main-hover-transition);
    color: var(--main-hover-color);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    font-size: 21px;
}
.detail_btnText__1zJ2v {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.detail_frames__3OTAl {
    width: 92%;
    position: relative;
    z-index: 1;
    border-bottom: 10px solid white;
}
.detail_frame__31AA4 {
    height: 100vh;
    box-sizing: border-box;
    border: 10px solid #fff;
    border-bottom: none;
    background-size: cover;
    background-repeat: no-repeat;
}
@media (min-width: 720px) {
    .detail_detail__2V1FF {
        --vertical-offset: 15vw;
    }
    .detail_first__1ydZB {
        height: 100vh;
    }
    .detail_wrapper__3doMb {
        padding-top: 5vw;
        padding-bottom: 10vh;
    }
    .detail_info__2Q-hy {
        padding-top: var(--vertical-offset);
        padding-right: 250px;
    }
    .detail_inner__2XSZM:hover {
        background-color: var(--main-hover-bgColor);
    }
}
@media (max-width: 720px) {
    .detail_detail__2V1FF {
        --vertical-offset: 30vw;
    }
    .detail_sideBar__J-OoJ {
        height: 100%;
    }
    .detail_background__1zE9f {
        background-position: 50% 50%;
    }
    .detail_first__1ydZB {
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-left: 0;
        padding-bottom: 48px;
        box-sizing: border-box;
    }
    .detail_outer__2n1RS {
        padding: 0 5% 0 5%;
    }
    .detail_wrapper__3doMb {
        padding-top: 10vw;
    }
    .detail_wrapper__3doMb::after {
        display: block;
        content: '';
        height: 10vh;
    }
    .detail_info__2Q-hy {
        width: 89%;
        top: 0;
        margin-top: var(--vertical-offset);
        left: 27px;
        -webkit-transform: none;
                transform: none;
    }
    .detail_title__g0r2D {
        font-size: 28px;
        margin-right: 0;
        margin-bottom: 24px;
    }
    .detail_text__o3FcX {
        width: auto;
        font-size: 12px;
        line-height: 1.5;
    }
    .detail_close__3L0GA {
        width: 50%;
        height: 48px;
        position: fixed;
        top: 100%;
        right: auto;
        left: 0;
        margin-top: -48px;
        -webkit-transform: none;
                transform: none;
        text-transform: lowercase;
    }
    .detail_link__BEayW {
        text-transform: lowercase;
    }
    .detail_frames__3OTAl {
        width: 100%;
    }
    .detail_frame__31AA4 {
        height: 25vh;
    }
    .detail_second__33k3h {
        width: 50%;
        height: 48px;
        position: fixed;
        right: 0;
        top: auto;
        bottom: 0;
        z-index: 1;
    }
    .detail_next__2v9lE {
        display: none;
    }
    .detail_inner__2XSZM {
        font-size: 40px;
    }
    .detail_btnText__1zJ2v {
        position: relative;
        top: auto;
        -webkit-transform: none;
                transform: none;
    }
}
.title {
    position: relative;
}
.title-base {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
}
.title-in {
    -webkit-animation-name: title-in;
            animation-name: title-in;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}
.title-out {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0);
    opacity: 0;
    -webkit-animation-name: title-out;
            animation-name: title-out;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}

@-webkit-keyframes title-in {
    0% {
        -webkit-transform: translate(300px, 0);
                transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes title-in {
    0% {
        -webkit-transform: translate(300px, 0);
                transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes title-out {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-300px, 0);
                transform: translate(-300px, 0);
        opacity: 0;
    }
}
@keyframes title-out {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-300px, 0);
                transform: translate(-300px, 0);
        opacity: 0;
    }
}
.text {
    position: relative;
}
.text-base {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
}
.text-in {
    -webkit-animation-name: text-in;
            animation-name: text-in;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}
.text-out {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-300px, 0);
            transform: translate(-300px, 0);
    opacity: 0;
    -webkit-animation-name: text-out;
            animation-name: text-out;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}

@-webkit-keyframes text-in {
    0% {
        -webkit-transform: translate(300px, 0);
                transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes text-in {
    0% {
        -webkit-transform: translate(300px, 0);
                transform: translate(300px, 0);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes text-out {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-300px, 0);
                transform: translate(-300px, 0);
        opacity: 0;
    }
}
@keyframes text-out {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-300px, 0);
                transform: translate(-300px, 0);
        opacity: 0;
    }
}
.close-enter {
    opacity: 0;
}
.close-enter-active {
    opacity: 1;
    -webkit-transition: opacity .7s .2s;
    transition: opacity .7s .2s;
}
.wrapper {
    position: relative;
}
.wrapper-base {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
.wrapper-in {
    -webkit-animation-name: wrapper-in;
            animation-name: wrapper-in;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}
.wrapper-out {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
    -webkit-animation-name: wrapper-out;
            animation-name: wrapper-out;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
}

@-webkit-keyframes wrapper-in {
    0% {
        -webkit-transform: translateY(200px);
                transform: translateY(200px);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}

@keyframes wrapper-in {
    0% {
        -webkit-transform: translateY(200px);
                transform: translateY(200px);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes wrapper-out {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(200px);
                transform: translateY(200px);
        opacity: 0;
    }
}
@keyframes wrapper-out {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(200px);
                transform: translateY(200px);
        opacity: 0;
    }
}
@media (max-width: 720px) {
    .close-enter {
        opacity: 1;
    }
    .close-enter-active {
        opacity: 1;
        -webkit-transition: opacity .7s .2s;
        transition: opacity .7s .2s;
    }
}
.logo_logo__2Rpvq {
    display: block;
    position: relative;
    z-index: 1;
}
.logo_desktopImage__1itGd,
.logo_mobileImage__i88Ij {
    display: none;
    width: 100%;
    height: 100%;
}
.logo_logo__2Rpvq {
    width: 100%;
}

@media (min-width: 720px) {
    .logo_desktopImage__1itGd {
        display: block;
    }
}
@media (max-width: 720px) {
    .logo_mobileImage__i88Ij {
        display: block;
    }
}
.isWorks .mobileLogo {
    display: none;
}

@media (max-width: 720px) {
    .isWorks .detailWorksLogo {
        display: none;
    }
    .isWorks .mobileLogo {
        display: block;
        position: absolute;
        top: 22px;
        left: 27px;
        width: 49px;
    }
    .isWorks .detailWorksHamburger {
        padding: 5px 10px;
        margin: 20px 10px 0 0;
        background-color: #fff;
        -webkit-transition: padding-top .7s, padding-bottom .7s, background-color 3.5s;
        transition: padding-top .7s, padding-bottom .7s, background-color 3.5s;
    }
    .isWorks .detailWorksHamburger span:first-child,
    .isWorks .detailWorksHamburger span:last-child {
        background-color: #000;
    }
    .isWorks .detailWorksHamburger span:first-child {
        margin-bottom: 5px;
    }
    .isWorks .detailWorksHamburger span:last-child {
        margin-top: 5px;
    }
    .isWorks .detailWorksHamburger.isOpen {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .isWorks .detailWorksHamburger.isOpen:hover {
        background-color: var(--main-hover-bgColor);
        -webkit-transition: padding-top .7s, padding-bottom .7s, background-color .5s;
        transition: padding-top .7s, padding-bottom .7s, background-color .5s;
    }
}
.aboutDetail_first__3xuAY {
    height: 100%;
    background-size: cover;
}
.aboutDetail_second__3S7eI {
    min-height: 100%;
    position: relative;
    overflow: auto;
}
.aboutDetail_btnClose__17KJ4::after,
.aboutDetail_btnClose__17KJ4::before {
    content: '';
    display: block;
    width: 141%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: -20%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #000;
    pointer-events: none;
}
.aboutDetail_btnClose__17KJ4::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.aboutDetail_btnClose__17KJ4::before  {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.aboutDetail_content__1K9J1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 16px;
}
.aboutDetail_content__1K9J1 h2,
.aboutDetail_content__1K9J1 h3,
.aboutDetail_content__1K9J1 p {
    margin-top: 0;
    margin-bottom: 0;
}
.aboutDetail_content__1K9J1 h2 {
    font-weight: bold;
    font-size: 1.5em;
}
.aboutDetail_content__1K9J1 h3 {
    font-weight: bold;
    font-size: 1.25em;
    margin-top: 0.2em;
}
.aboutDetail_content__1K9J1 p {
    margin-top: 2.8em;
}
.aboutDetail_content__1K9J1 p + p {
    margin-top: 1.6em;
}

@media (min-width: 720px) {
    .aboutDetail_btnClose__17KJ4 {
        position: absolute;
        width: 51px;
        height: 51px;
        top: 75px;
        right: 75px;
        -webkit-transition: -webkit-transform 1s;
        transition: -webkit-transform 1s;
        transition: transform 1s;
        transition: transform 1s, -webkit-transform 1s;    
    }
    .aboutDetail_btnClose__17KJ4:hover {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
    .aboutDetail_content__1K9J1 {
        margin: 30%  0 20% 120px;
        width: 60%;
    }
}
@media (max-width: 720px) {
    .aboutDetail_first__3xuAY {
        background-position: left center;
    }
    .aboutDetail_second__3S7eI {
        /*
            on iOS devices overflow auto cuts fixed children elements (.btnClose in this case)
            so both following rules are for fixing that bug
         */
        margin-right: -10%;
        padding-right: 10%;
    }
    .aboutDetail_btnClose__17KJ4 {
        position: fixed;
        width: 46px;
        height: 46px;
        right: 12px;
        bottom: calc(30vh + 12px);
        background-color: white;
        border: 10px solid white;
        box-sizing: border-box;
    }
}

.app_App__3W95g {
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
}

.app_App-logo__1RrgR {
    -webkit-animation: app_App-logo-spin__1ZPXr infinite 20s linear;
            animation: app_App-logo-spin__1ZPXr infinite 20s linear;
    height: 40vmin;
}

.app_App-header__1tpDB {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.app_App-link__1c_gz {
    color: #61dafb;
}

@-webkit-keyframes app_App-logo-spin__1ZPXr {
    from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@keyframes app_App-logo-spin__1ZPXr {
    from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@font-face {
    font-family: MuseoSansCyrl;
    src: url(/static/media/MuseoSansCyrl_Black.88380320.eot) format('eot'),
    url(/static/media/MuseoSansCyrl_Black.b463ae86.woff) format('woff'),
    url(/static/media/MuseoSansCyrl_Black.73e5c73d.otf) format('otf'),
    url(/static/media/MuseoSansCyrl_Black.8ada7ad7.ttf) format('ttf'),
    url(/static/media/MuseoSansCyrl_Black.73e5c73d.svg#MuseoSansCyrl_Black) format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: MuseoSansCyrl;
    src: url(/static/media/MuseoSansCyrl_Regular.02f7d6b8.otf) format('otf'),
    url(/static/media/MuseoSansCyrl_Regular.be304e15.woff) format('woff'),
    url(/static/media/MuseoSansCyrl_Regular.e5d22d53.svg#MuseoSansCyrl_Regular) format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: MuseoSansCyrl;
    src: url(/static/media/MuseoSansCyrl_Thin.1913b098.otf) format('otf'),
    url(/static/media/MuseoSansCyrl_Thin.8bf2821a.woff) format('woff'),
    url(/static/media/MuseoSansCyrl_Thin.6d0bd4b6.svg#MuseoSansCyrl_Thin) format('svg');
    font-weight: 100;
    font-style: normal;
}

.wrapper_wrapper__2VYTs {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: var(--main-bg-color);
}
.wrapper_main__3tWYD{
    width: 100%;
    position: relative;
}
.wrapper_logo__3nvwL,
.wrapper_menu__3AZ13 {
    position: absolute;
    z-index: 1;
}
.wrapper_hamburger__3TIUS {
    position: absolute;
    z-index: 2;
}
.wrapper_siteMap__2KPCi {
    z-index: 1;
}

.wrapper_content__251pw {
    position: relative;
    z-index: 0;
    width: 100%;
    min-height: 100%;
}


@media (min-width: 720px) {
    .wrapper_logo__3nvwL {
        top: 64px;
        left: 76px;
        width: 8%;
        min-width: 120px;
    }
    .wrapper_menu__3AZ13 {
        top: 93px;
        right: 292px;
        z-index: 1;
    }
    .wrapper_hamburger__3TIUS {
        top: 50%;
        left: 53px;
    }
}
@media (max-width: 720px) {
    .wrapper_logo__3nvwL {
        top: 22px;
        left: 27px;
        width: 49px;
    }
    .wrapper_menu__3AZ13 {
        display: none;
    }
    .wrapper_hamburger__3TIUS {
        top: 0;
        right: 0;
    }
}

.siteMap_wrapper__CTH0m {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    position: absolute;
    background-color: #c6ff00;
    background-color: var(--main-hover-bgColor: #c6ff00);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    z-index: 9;
}
.siteMap_isOpen__1oQqX {
    top: 0;
    bottom: 0;
}

.siteMap_content__185Bt {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
}


@media (min-width: 720px) {
    .siteMap_wrapper__CTH0m,
    .siteMap_wrapper__CTH0m.siteMap_isClosed__lE69O {
        top: 50vh;
        bottom: 50vh;
        left: 0;
    }
    .siteMap_wrapper__CTH0m.siteMap_isClosed__lE69O {
        -webkit-animation-name: siteMap_desktop-out__2QoML;
                animation-name: siteMap_desktop-out__2QoML;
        -webkit-animation-duration: 0.45s;
                animation-duration: 0.45s;
    }
    .siteMap_wrapper__CTH0m.siteMap_isOpen__1oQqX {
        top: 0;
        bottom: 0;
        -webkit-animation-name: siteMap_desktop-in__1DIKN;
                animation-name: siteMap_desktop-in__1DIKN;
        -webkit-animation-duration: 0.8s;
                animation-duration: 0.8s;
    }
    .siteMap_wrapper__CTH0m .siteMap_content__185Bt {
        opacity: 0;
        -webkit-transition: 0.2s;
        transition: 0.2s;
    }
    .siteMap_wrapper__CTH0m.siteMap_isOpen__1oQqX .siteMap_content__185Bt {
        opacity: 1;
        -webkit-transition: 0.4s 0.6s;
        transition: 0.4s 0.6s;
    }
    .siteMap_isInverted__3_s6c {
        background-color: white;
    }
}
@media (max-width: 720px) {
    .siteMap_wrapper__CTH0m,
    .siteMap_wrapper__CTH0m.siteMap_isClosed__lE69O {
        right: 0;
        top: 0;
        width: 0;
        height: 0;
    }
    .siteMap_wrapper__CTH0m.siteMap_isClosed__lE69O {
        -webkit-animation-name: siteMap_mobile-out__1CFGz;
                animation-name: siteMap_mobile-out__1CFGz;
        -webkit-animation-duration: 0.8s;
                animation-duration: 0.8s;
    }
    .siteMap_wrapper__CTH0m.siteMap_isOpen__1oQqX {
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-animation-name: siteMap_mobile-in__RdH3m;
                animation-name: siteMap_mobile-in__RdH3m;
        -webkit-animation-duration: 0.8s;
                animation-duration: 0.8s;
    }
    .siteMap_wrapper__CTH0m .siteMap_content__185Bt {
        opacity: 0;
        -webkit-transition: 0.2s;
        transition: 0.2s;
    }
    .siteMap_wrapper__CTH0m.siteMap_isOpen__1oQqX .siteMap_content__185Bt {
        opacity: 1;
        -webkit-transition: 0.4s 0.6s;
        transition: 0.4s 0.6s;
    }
}

@-webkit-keyframes siteMap_desktop-in__1DIKN {
    0% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: 0;
        bottom: 0;
    }
}

@keyframes siteMap_desktop-in__1DIKN {
    0% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: 0;
        bottom: 0;
    }
}
@-webkit-keyframes siteMap_desktop-out__2QoML {
    0% {
        top: 0;
        bottom: 0;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
}
@keyframes siteMap_desktop-out__2QoML {
    0% {
        top: 0;
        bottom: 0;
    }
    50% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 0;
        width: 100%;
    }
    100% {
        top: calc(50vh - 20px);
        bottom: calc(50vh - 20px);
        left: 53px;
        width: 126px;
    }
}
@-webkit-keyframes siteMap_mobile-in__RdH3m {
    0% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
    30% {
        height: 65px;
        opacity: 1;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 100%;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}
@keyframes siteMap_mobile-in__RdH3m {
    0% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
    30% {
        height: 65px;
        opacity: 1;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 100%;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}
@-webkit-keyframes siteMap_mobile-out__1CFGz {
    0% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    30% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
}
@keyframes siteMap_mobile-out__1CFGz {
    0% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    30% {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    70% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 1;
    }
    100% {
        top: 0;
        right: 0;
        width: 82px;
        height: 65px;
        opacity: 0;
    }
}

.siteMapMenu_siteMapMenu__1A1gC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    line-height: 1.35em;
    position: relative;
}
.siteMapMenu_link__4t8z4 {
    text-decoration: none;
    color: #000;
}
.siteMapMenu_item__3pCWX + .siteMapMenu_item__3pCWX {
    margin-top: 1em;
}

@media (min-width: 720px) {
    .siteMapMenu_siteMapMenu__1A1gC {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 269px;
        font-size: 72px;
    }
}
@media (max-width: 720px) {
    .siteMapMenu_siteMapMenu__1A1gC {
        -webkit-justify-content: center;
                justify-content: center;
        padding-left: 12vw;
        font-size: 92px;
    }
}
@media (max-width: 470px) {
    .siteMapMenu_siteMapMenu__1A1gC {
        font-size: 58px;
    }
}

.imagesForMenuItems_imagesForMenuItems__36BMQ {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.imagesForMenuItems_imageWrapper__19abj {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    -webkit-animation-name: imagesForMenuItems_appearing__5CEy1;
            animation-name: imagesForMenuItems_appearing__5CEy1;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
}
.imagesForMenuItems_isUnactive__2a16V {
    opacity: 0;
    -webkit-animation-name: imagesForMenuItems_disappearing__1nmvc;
            animation-name: imagesForMenuItems_disappearing__1nmvc;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
}
.imagesForMenuItems_image__2ieQv {
    min-width: 100%;
    height: 100%;
}

@media (max-width: 720px) {
    .imagesForMenuItems_imagesForMenuItems__36BMQ {
        display: none;
    }
}

@-webkit-keyframes imagesForMenuItems_appearing__5CEy1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes imagesForMenuItems_appearing__5CEy1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes imagesForMenuItems_disappearing__1nmvc {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes imagesForMenuItems_disappearing__1nmvc {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.menu_menu__3N5Ts {
    display: inline-block;
}
.menu_link__2s4WY {
    text-decoration: none;
    color: var(--main-link-color);
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: 0;
    opacity: 1;
    -webkit-transition: background-color var(--main-hover-transition), color var(--main-hover-transition), top 0s 1.5s, opacity 0s 1.5s;
    transition: background-color var(--main-hover-transition), color var(--main-hover-transition), top 0s 1.5s, opacity 0s 1.5s;
}
    .menu_link__2s4WY.menu_hidden__2Hl46 {
        top: -20px;
        opacity: 0;
        pointer-events: none;
    }
.menu_link__2s4WY.menu_detached__9dtdR {
    position: fixed;
    top: 0;
    right: 0;
    width: 190px;
    height: 100%;
    padding-top: 93px;
    text-align: center;
    color: #000;
    background-color: #fff;
    -webkit-transition: background-color var(--main-hover-transition), right 0s 1.5s, opacity 0s 1.5s;
    transition: background-color var(--main-hover-transition), right 0s 1.5s, opacity 0s 1.5s;
}
.menu_link__2s4WY.menu_detached__9dtdR:hover {
    background-color: var(--main-hover-bgColor);
}
    .menu_link__2s4WY.menu_detached__9dtdR.menu_hidden__2Hl46 {
        right: -190px;
        opacity: 1;
    }
.menu_link__2s4WY:first-child {
    margin-right: 65px;
}
.menu_link__2s4WY:hover  {
    color: var(--main-hover-color);
}

.hamburger_hamburger__3d6dq {
    -webkit-transition: background-color var(--main-hover-transition);
    transition: background-color var(--main-hover-transition);
    cursor: pointer;
}
.hamburger_top__3SbuQ,
.hamburger_bottom__3sON7 {
    display: block;
    height: 3px;
    background-color: var(--main-link-color);
    -webkit-transition: background-color var(--main-hover-transition);
    transition: background-color var(--main-hover-transition);
}
.hamburger_top__3SbuQ {
    margin-bottom: 9px;
}
.hamburger_bottom__3sON7 {
    margin-top: 9px;
}
.hamburger_isOpen__3VU8Q .hamburger_top__3SbuQ,
.hamburger_isOpen__3VU8Q .hamburger_bottom__3sON7 {
    background-color: var(--main-hover-color);
}
.hamburger_isOpen__3VU8Q .hamburger_top__3SbuQ {
    -webkit-animation: hamburger_top__3SbuQ .6s .2s;
            animation: hamburger_top__3SbuQ .6s .2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.hamburger_isOpen__3VU8Q .hamburger_bottom__3sON7 {
    -webkit-animation: hamburger_bottom__3sON7 .6s .2s;
            animation: hamburger_bottom__3sON7 .6s .2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
@-webkit-keyframes hamburger_top__3SbuQ {
    0% {
        
    }
    20% {
        margin-bottom: -3px;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
    100%{
        -webkit-transform: rotateZ(-45deg);
                transform: rotateZ(-45deg);
        margin-bottom: -3px;
    }
}
@keyframes hamburger_top__3SbuQ {
    0% {
        
    }
    20% {
        margin-bottom: -3px;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
    100%{
        -webkit-transform: rotateZ(-45deg);
                transform: rotateZ(-45deg);
        margin-bottom: -3px;
    }
}
@-webkit-keyframes hamburger_bottom__3sON7 {
    0% {
        
    }
    20% {
        margin-top: -3px;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
    100%{
        -webkit-transform: rotateZ(45deg);
                transform: rotateZ(45deg);
        margin-top: 0;
    }
}
@keyframes hamburger_bottom__3sON7 {
    0% {
        
    }
    20% {
        margin-top: -3px;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
    100%{
        -webkit-transform: rotateZ(45deg);
                transform: rotateZ(45deg);
        margin-top: 0;
    }
}
@media (min-width: 720px) {
    .hamburger_hamburger__3d6dq {
        padding: 12px 22px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    .hamburger_hamburger__3d6dq:hover {
        background-color: var(--main-hover-bgColor);
    }
    .hamburger_isOpen__3VU8Q {
        padding-top: 42px;
        padding-bottom: 42px;
        -webkit-transition: -webkit-transform 1s;
        transition: -webkit-transform 1s;
        transition: transform 1s;
        transition: transform 1s, -webkit-transform 1s;
        -webkit-transform: translateY(-50%) rotate(0deg);
                transform: translateY(-50%) rotate(0deg);
    }
    .hamburger_isOpen__3VU8Q:hover {
        background-color: transparent;
        -webkit-transform: translateY(-50%) rotate(720deg);
                transform: translateY(-50%) rotate(720deg);
    }
    .hamburger_hamburger__3d6dq:hover .hamburger_top__3SbuQ,
    .hamburger_hamburger__3d6dq:hover .hamburger_bottom__3sON7 {
        background-color: var(--main-hover-color);
    }
    .hamburger_top__3SbuQ,
    .hamburger_bottom__3sON7 {
        width: 82px;
    }
}
@media (max-width: 720px) {
    .hamburger_hamburger__3d6dq {
        padding: 25px 20px;
    }
    .hamburger_top__3SbuQ,
    .hamburger_bottom__3sON7 {
        width: 42px;
    }
}
/* .slogan {   
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .3s;
    z-index: -1;
} */
#text2 {
    font-style: italic;
}

@media (min-width: 720px) {
    #text {
        font-size: 41px;
    }
    #text2 {
        font-size: 16px;
    }
}

@media (max-width: 720px) {
    #text {
        font-size: 16px;
    }
    #text2 {
        font-size: 12px;
    }
}
.outer {
    width: 100%;
    height: 100%;
}
